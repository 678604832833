import React from 'react';
import Layout from '../components/layout';
import "../styles/app.scss"

export default function Home() {
  return (
    <Layout
      title='Contact'
      metaDescription="HSBC Intermediaries Contact"
    >
      <h1 className='page-title'>HSBC Intermediaries Contact</h1>
    </Layout>
  );
}